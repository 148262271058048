import axios from 'axios'
import {backEndURL} from '@/siteConfig'
import {ref} from 'vue'

const researchData = ref({
    inited:false,
    leftRightDepth:2,
    home:[] as any[],
    allNews:[] as any[],
    blocks:[
        {
            title:'',
            tab:'',
            children:[] as any[],
        },]
})

export default researchData

axios.get(`${backEndURL}/public/research`)
.then((research:{data:{title:string, article_type:string}[]})=>{
    console.log('research data', research.data)
    research.data = research.data.filter(x=>!x.title.includes("赵焕焱"))
    const home1 = research.data.filter(x=>x.article_type=='热点专题')[0]
    const home2 = research.data.filter(x=>x.article_type=='热点专题')[1]
    const home3 = research.data.filter(x=>x.article_type=='热点专题')[2]
    console.log('热点专题',research.data.filter(x=>x.article_type=='热点专题'))
    researchData.value = {
            inited:true,
            leftRightDepth:2,
            home:[ home1, home2, home3 ],
            allNews:research.data,
            blocks:[
                // {
                //     tab:'首席知识官\n赵焕焱',
                //     title:'首席知识官\n赵焕焱',
                //     children:research.data.filter(x=>x.article_type=='首席知识官赵焕焱')
                // },
                {
                    title:'热点报告',
                    tab:'热点\n报告',
                    children: research.data.filter(x=>x.article_type=='热点专题')
                },
                // {
                //     title:'行业\n数据',
                //     children:research.data.filter(x=>x.article_type=='行业数据')
                // },
                {
                    title:'重要报告',
                    tab:'热点\n报告',
                    children:research.data.filter(x=>x.article_type=='城市数据' || x.article_type=='品牌数据')
                },
        ]
    }
})
.catch(err=>{
    return
})