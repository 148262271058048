import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e19270be"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "timage_wrap",
    style: _normalizeStyle(_ctx._style)
  }, [
    _createElementVNode("img", {
      src: _ctx.src || _ctx.src || _ctx.store.state.defaultHeadImg,
      ref: "timage",
      alt: "",
      class: "_timage",
      style: _normalizeStyle(_ctx._style)
    }, null, 12, _hoisted_1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imagesDisplay, (img, i) => {
      return (_openBlock(), _createElementBlock("img", {
        class: "decor_img",
        key: img,
        style: _normalizeStyle(_ctx.positions[i]),
        src: img
      }, null, 12, _hoisted_2))
    }), 128))
  ], 4))
}