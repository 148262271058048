
import { defineComponent, PropType, ref } from 'vue'
import {fetchImg} from '@/utils/fetchImg' 
import store from '@/store'

export default defineComponent({
  name: "Nav",
  props:{
      src: {
          default:'',
          type:String as PropType<string>
      },
      _style: {
          default:'',
          type:String as PropType<string>
      },
      _direction: {default:'right'}
  },
  components:{

  },
  setup(props){
    const imageSource = ref('')
    
    fetchImg(props.src) 
    .then( blob_url => {
        imageSource.value = blob_url
    })

    const positions = [] as string[]

    let images = [
        "/images/splash/splash_blue.png",
        "/images/splash/splash_green.png",
        "/images/splash/splash_red.png",
        "/images/splash/splash_yellow.png",
        "/images/splash/splash_orange.png",
        "/images/splash/splash_purple.png",
    ]
    const imagesDisplay = ref<string[]>([])
    /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
    while(true){
        const randImg = images[Math.floor(Math.random() * images.length)]
        if(!imagesDisplay.value.includes(randImg)){
            imagesDisplay.value.push(randImg)
            if(imagesDisplay.value.length  == images.length ){
                break
            }
        }
    }
    for(let i = 0; i < images.length; i++){

        const widthHeight = 30 + 10 * Math.random()

        const randRange = 15
        const topRand = Math.random() * randRange
        const leftRand = Math.random() * randRange

        let topDist = 0
        let leftDist = 0

        if(i==0){
            topDist = topRand - widthHeight/2
            leftDist = leftRand  - widthHeight/2
        }else if(i==1){
            topDist = topRand  - widthHeight/2
            leftDist = (100-randRange/2) + leftRand  - widthHeight
        }else if(i==2){
            topDist = (100-randRange/2) + topRand - widthHeight
            leftDist = leftRand - widthHeight/2
        }else if(i==3){
            topDist = (100-randRange/2) + topRand - widthHeight
            leftDist = (100-randRange/2) + leftRand  - widthHeight
        }else{
            topDist = topRand > randRange/2 ? (100-randRange/2) + topRand - widthHeight : topRand  - widthHeight/2
            leftDist = leftRand > randRange/2 ? (100-randRange/2) + leftRand  - widthHeight : leftRand - widthHeight/2
        }

        const curr = `
            height:${widthHeight}%;width:${widthHeight}%;
            top:${topDist}%;
            left:${leftDist}%;
            transform:rotate(${Math.random() * 180}deg);`
        positions.push(curr)
    }

    return { 
        imageSource,
        imagesDisplay,
        store,
        positions,
    }
  },
})
