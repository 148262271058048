
import { useRouter } from 'vue-router';
import store from '@/store'
import {modal} from '@/modal'
import { defineComponent, ref, computed, watch, PropType, onMounted } from 'vue'
import {pages, PageItem}  from '@/siteConfig'

export default defineComponent({
  name: "SocialMedia",
  props:{
      iconSize: {
          default:'width:50px;height:50px;'
      }
  },
  components:{
  },
  setup(){

      const showQR = (name:string)=> {
        switch (name){
            case "wechat":{
                modal.display('扫描二维码关注最新动态', 'images/wxaccqrcode.jpg')
                break
            }
            case "cjd":{
                modal.display('扫描二维码打开“查酒店”小程序', 'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%A4%BE%E4%BA%A4%E5%AA%92%E4%BD%93/%E6%9F%A5%E9%85%92%E5%BA%97QR.png')
                break
            }
            case "ppq":{
                modal.display('扫描二维码打开“品牌墙”小程序', 'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%A4%BE%E4%BA%A4%E5%AA%92%E4%BD%93/%E5%93%81%E7%89%8C%E5%A2%99QR.png')
                break
            }
            case "hjl":{
                modal.display('扫描二维码打开“环酒历”小程序', 'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%A4%BE%E4%BA%A4%E5%AA%92%E4%BD%93/%E7%8E%AF%E9%85%92%E5%8E%86QR.png')
                break
            }
        }

      }
    return { 
        showQR,
        modal
    }
  },
})
