
import { defineComponent } from 'vue'
import SocialMedia from './SocialMedia.vue'

const defaultText = "关注华美酒店顾问公众号，分享最新行业大数据！"

export default defineComponent({
  name: "CallForAction",
  props:{
      config:{
          default:{
              call_text:defaultText
          }
      }
  },
  components:{
      SocialMedia
  },
  setup(){
    return { 
        defaultText
    }
  },
})
